import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Hero from 'e84-website-styles/src/components/Hero';
import WonderLink from 'e84-website-styles/src/components/WonderLink';

import ThankYouIcon from 'assets/images/ThankYou_Icon.svg';

const SuccessPage = () => {

  const helmet_settings = {
    title: 'Signup Successful! - CubeSat Data',
    meta: [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
    bodyAttributes: {
      class: `page-cubesat page-cubesat-success`,
    },
  };

  return (
    <Layout>
      <div className="thank-you__wrapper row">
        <Helmet { ...helmet_settings } />

        <div className="thank-you__middle-content">
          <div className="thank-you__icon">
            <img src={ ThankYouIcon } alt="Illustration of a check mark!" />
          </div>
          <Hero>
            <div className="text-center">
              <h1>Signup Successful!</h1>
              <section className="top-content">
                <p>
                  We appreciate you signing up. We’ll let you know when all
                  systems are go!
                </p>
              </section>
            </div>
          </Hero>
          <div className="row">
            <div className="six columns offset-by-three text-center">
              <h2 className="narrow">While you&#39;re here...</h2>
              <p>
                Learn more{ ' ' }
                <WonderLink to="https://www.element84.com/about-us">
                  about E84
                </WonderLink>
                , take a look at{ ' ' }
                <WonderLink to="https://www.element84.com/work">
                  our work
                </WonderLink>
                , or read some posts from{ ' ' }
                <WonderLink to="https://www.element84.com/blog">
                  our blog
                </WonderLink>
              </p>
              <p>
                Or go back to our{ ' ' }
                <WonderLink to="/">
                  data pipelines for cubesat and microsatellite programs
                </WonderLink>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );

};

export default SuccessPage;
